@keyframes top-open {
  30% {
    transform: translateY(0);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes top-close {
  0% {
    transform: rotate(45deg);
  }

  70% {
    transform: rotate(0);
  }
  100% {
    transform: translateY(-8px);
  }
}

@keyframes bottom-open {
  30% {
    transform: translateY(0);
  }
  100% {
    transform: rotate(135deg);
  }
}

@keyframes bottom-close {
  0% {
    transform: rotate(135deg);
  }
  70% {
    transform: rotate(0);
  }
  100% {
    transform: translateY(8px);
  }
}
