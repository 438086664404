@import url(https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@600&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
}

* {
  box-sizing: border-box;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  height: 100%;
}

html {
  background-color: #272725;
}



.custom-emoji-picjer-styles .emoji-picker-react .emoji-group:before {
  top: -2px;
}

.custom-emoji-picjer-styles .emoji-picker-react .emoji-categories {
  padding-top: 5px;
}

.custom-emoji-picjer-styles
  .emoji-picker-react
  .emoji-categories
  button.icn-activities {
  background-image: url(/static/media/basketball-solid.70b14332.svg);
}
.custom-emoji-picjer-styles
  .emoji-picker-react
  .emoji-categories
  button.icn-animals_nature {
  background-image: url(/static/media/dog-solid.deada94c.svg);
}
.custom-emoji-picjer-styles
  .emoji-picker-react
  .emoji-categories
  button.icn-flags {
  background-image: url(/static/media/flag-solid.39835bcc.svg);
}
.custom-emoji-picjer-styles
  .emoji-picker-react
  .emoji-categories
  button.icn-food_drink {
  background-image: url(/static/media/burger-solid.3fbd1e99.svg);
}
.custom-emoji-picjer-styles
  .emoji-picker-react
  .emoji-categories
  button.icn-objects {
  background-image: url(/static/media/lightbulb-solid.37fc4943.svg);
}
.custom-emoji-picjer-styles
  .emoji-picker-react
  .emoji-categories
  button.icn-smileys_people {
  background-image: url(/static/media/face-grin-beam-solid.a33a334a.svg);
}
.custom-emoji-picjer-styles
  .emoji-picker-react
  .emoji-categories
  button.icn-symbols {
  background-image: url(/static/media/icons-solid.7167ae4c.svg);
}
.custom-emoji-picjer-styles
  .emoji-picker-react
  .emoji-categories
  button.icn-travel_places {
  background-image: url(/static/media/map-location-solid.22c99303.svg);
}

@-webkit-keyframes top-open {
  30% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}

@keyframes top-open {
  30% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}

@-webkit-keyframes top-close {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }

  70% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
}

@keyframes top-close {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }

  70% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
}

@-webkit-keyframes bottom-open {
  30% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
}

@keyframes bottom-open {
  30% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
}

@-webkit-keyframes bottom-close {
  0% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  70% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
}

@keyframes bottom-close {
  0% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  70% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
}

body.modal-open {
  overflow: hidden;
}


