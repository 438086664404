.custom-emoji-picjer-styles .emoji-picker-react .emoji-group:before {
  top: -2px;
}

.custom-emoji-picjer-styles .emoji-picker-react .emoji-categories {
  padding-top: 5px;
}

.custom-emoji-picjer-styles
  .emoji-picker-react
  .emoji-categories
  button.icn-activities {
  background-image: url(../../../assets/images/emoji/basketball-solid.svg);
}
.custom-emoji-picjer-styles
  .emoji-picker-react
  .emoji-categories
  button.icn-animals_nature {
  background-image: url(../../../assets/images/emoji/dog-solid.svg);
}
.custom-emoji-picjer-styles
  .emoji-picker-react
  .emoji-categories
  button.icn-flags {
  background-image: url(../../../assets/images/emoji/flag-solid.svg);
}
.custom-emoji-picjer-styles
  .emoji-picker-react
  .emoji-categories
  button.icn-food_drink {
  background-image: url(../../../assets/images/emoji/burger-solid.svg);
}
.custom-emoji-picjer-styles
  .emoji-picker-react
  .emoji-categories
  button.icn-objects {
  background-image: url(../../../assets/images/emoji/lightbulb-solid.svg);
}
.custom-emoji-picjer-styles
  .emoji-picker-react
  .emoji-categories
  button.icn-smileys_people {
  background-image: url(../../../assets/images/emoji/face-grin-beam-solid.svg);
}
.custom-emoji-picjer-styles
  .emoji-picker-react
  .emoji-categories
  button.icn-symbols {
  background-image: url(../../../assets/images/emoji/icons-solid.svg);
}
.custom-emoji-picjer-styles
  .emoji-picker-react
  .emoji-categories
  button.icn-travel_places {
  background-image: url(../../../assets/images/emoji/map-location-solid.svg);
}
